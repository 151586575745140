import React from 'react';

// const LogoIcon = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
//     <path fill="#BEE3F8" d="M44,7L4,23l40,16l-7-16L44,7z M36,23H17l18-7l1,6V23z" />
//     <path
//       fill="#3182CE"
//       d="M40.212,10.669l-5.044,11.529L34.817,23l0.351,0.802l5.044,11.529L9.385,23L40.212,10.669 M44,7L4,23 l40,16l-7-16L44,7L44,7z"
//     />
//     <path
//       fill="#3182CE"
//       d="M36,22l-1-6l-18,7l17,7l-2-5l-8-2h12V22z M27.661,21l5.771-2.244L33.806,21H27.661z"
//     />
//   </svg>
// );

// export default LogoIcon;

const styles = {
  animateO: {
    strokeDasharray: 210,
    strokeDashoffset: 210,
    animation: "$dash 1.5s linear forwards"
  },
  animateS: {
    strokeDasharray: 370,
    strokeDashoffset: 370,
    animation: "$dash 1.5s linear forwards",
  },
  animateC: {
    strokeDasharray: 334,
    strokeDashoffset: 334,
    animation: "$dash 1.5s linear forwards"
  },
  animateM: {
    strokeDasharray: 557,
    strokeDashoffset: 557,
    animation: "$dash 1.5s linear forwards",
  },
  "@keyframes dash": {
    to: {
      strokeDashoffset: 0
    }
  }
}

const theme = {
  type: 'light',
  palette: {
    primary: {
      main: '#607D8B',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#EC407A',
      contrastText: '#ffffff'
    }
  }
}

export default React.memo(({animate, compact, ...props}) => {

  
  return (
    compact ?
    <svg viewBox="-2 0 357 195" {...props}>
      <g transform="translate(0, 0)">
        <path style={animate && styles.animateC} stroke={theme.palette.secondary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" fill="transparent" d="M 45.313 52.441 L 54.737 52.441 A 33.516 33.516 0 0 1 52.948 60.176 A 23.323 23.323 0 0 1 46.412 69.849 A 23.964 23.964 0 0 1 35.928 75.097 Q 32.304 75.949 28.058 75.976 A 43.766 43.766 0 0 1 27.784 75.977 A 28.37 28.37 0 0 1 18.127 74.398 A 24.377 24.377 0 0 1 7.593 66.943 A 30.992 30.992 0 0 1 1.352 54.459 Q 0.169 49.822 0.022 44.353 A 58.464 58.464 0 0 1 0.001 42.773 L 0.001 35.938 A 47.233 47.233 0 0 1 0.778 27.171 A 35.875 35.875 0 0 1 3.541 18.506 A 28.212 28.212 0 0 1 8.601 11 A 25.109 25.109 0 0 1 13.575 6.958 A 26.83 26.83 0 0 1 24.974 3.122 A 33.631 33.631 0 0 1 28.614 2.93 A 32.911 32.911 0 0 1 36.459 3.814 A 22.95 22.95 0 0 1 46.778 9.253 A 24.003 24.003 0 0 1 53.385 19.943 A 34.289 34.289 0 0 1 54.737 26.758 L 45.313 26.758 Q 44.548 21.435 42.663 17.952 A 13.588 13.588 0 0 0 40.015 14.453 A 13.407 13.407 0 0 0 34.401 11.374 Q 32.323 10.787 29.842 10.672 A 26.621 26.621 0 0 0 28.614 10.645 A 19.477 19.477 0 0 0 22.187 11.658 A 16.253 16.253 0 0 0 14.527 17.285 Q 9.424 23.926 9.424 36.182 L 9.424 43.066 Q 9.424 51.672 12.098 57.659 A 22.422 22.422 0 0 0 14.258 61.475 A 15.335 15.335 0 0 0 25.352 68.168 A 20.16 20.16 0 0 0 27.784 68.311 A 27.742 27.742 0 0 0 32.311 67.965 Q 34.75 67.561 36.702 66.685 A 12.836 12.836 0 0 0 39.771 64.771 A 12.529 12.529 0 0 0 42.604 61.204 Q 44.421 57.904 45.26 52.774 A 43.507 43.507 0 0 0 45.313 52.441 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 63.819 50.049 L 63.819 48.389 A 35.217 35.217 0 0 1 64.666 40.511 A 28.681 28.681 0 0 1 66.822 34.351 A 24.345 24.345 0 0 1 71.794 27.4 A 22.592 22.592 0 0 1 75.22 24.683 A 21.115 21.115 0 0 1 86.915 21.191 A 25.048 25.048 0 0 1 93.727 22.068 A 18.214 18.214 0 0 1 102.93 27.979 Q 108.643 34.766 108.643 47.412 L 108.643 51.172 L 72.852 51.172 A 23.461 23.461 0 0 0 73.642 56.817 A 16.845 16.845 0 0 0 77.418 63.794 A 14.244 14.244 0 0 0 87.796 68.589 A 18.354 18.354 0 0 0 88.526 68.604 A 19.593 19.593 0 0 0 92.172 68.281 Q 94.495 67.841 96.367 66.801 A 12.287 12.287 0 0 0 96.631 66.65 Q 99.952 64.697 102.442 61.475 L 107.96 65.771 A 21.402 21.402 0 0 1 92.407 75.675 A 30.546 30.546 0 0 1 88.038 75.977 A 26.605 26.605 0 0 1 79.691 74.722 A 21.937 21.937 0 0 1 70.557 68.921 A 23.728 23.728 0 0 1 64.51 57.13 A 34.476 34.476 0 0 1 63.819 50.049 Z M 73.145 43.75 L 99.61 43.75 L 99.61 43.066 Q 99.321 37.968 97.424 34.569 A 12.557 12.557 0 0 0 95.899 32.397 A 10.923 10.923 0 0 0 88.627 28.706 A 15.439 15.439 0 0 0 86.915 28.613 Q 81.446 28.613 77.735 32.593 Q 74.473 36.09 73.399 42.059 A 30.452 30.452 0 0 0 73.145 43.75 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 163.721 48.34 L 163.721 49.17 A 42.978 42.978 0 0 1 163.009 57.233 Q 162.101 61.985 160.035 65.753 A 23.997 23.997 0 0 1 158.204 68.604 A 17.447 17.447 0 0 1 145.557 75.861 A 22.362 22.362 0 0 1 143.262 75.977 Q 133.643 75.977 128.126 69.873 L 128.126 95.313 L 119.092 95.313 L 119.092 22.168 L 127.344 22.168 L 127.784 28.027 Q 133.301 21.191 143.116 21.191 A 20.317 20.317 0 0 1 150.195 22.376 A 17.739 17.739 0 0 1 158.179 28.369 Q 162.709 34.236 163.536 43.855 A 52.378 52.378 0 0 1 163.721 48.34 Z M 128.126 36.133 L 128.126 61.377 A 14.793 14.793 0 0 0 131.769 65.781 Q 134.756 68.162 138.938 68.535 A 17.933 17.933 0 0 0 140.528 68.604 A 13.154 13.154 0 0 0 145.701 67.618 Q 148.699 66.35 150.855 63.452 Q 154.688 58.301 154.688 48.145 A 33.969 33.969 0 0 0 154.258 42.565 Q 153.409 37.47 150.879 34.033 A 12.07 12.07 0 0 0 142.144 28.949 A 15.709 15.709 0 0 0 140.43 28.857 A 14.51 14.51 0 0 0 134.699 29.945 Q 130.641 31.671 128.126 36.133 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 184.229 0 L 184.229 28.564 Q 190.235 21.191 199.854 21.191 A 21.166 21.166 0 0 1 206.893 22.264 Q 216.637 25.694 216.749 40.088 L 216.749 75 L 207.715 75 L 207.715 40.039 Q 207.667 34.326 205.103 31.592 Q 203.035 29.386 199.109 28.96 A 18.441 18.441 0 0 0 197.12 28.857 A 13.921 13.921 0 0 0 192.516 29.599 A 12.481 12.481 0 0 0 189.405 31.201 A 15.915 15.915 0 0 0 184.229 37.354 L 184.229 75 L 175.196 75 L 175.196 0 L 184.229 0 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 272.364 75 L 262.891 75 Q 262.11 73.438 261.622 69.434 A 21.481 21.481 0 0 1 254.712 74.341 A 20.155 20.155 0 0 1 246.583 75.977 A 22.593 22.593 0 0 1 240.702 75.248 A 16.988 16.988 0 0 1 233.765 71.558 A 14.202 14.202 0 0 1 228.781 61.217 A 17.812 17.812 0 0 1 228.76 60.352 A 17.307 17.307 0 0 1 229.699 54.521 A 14.557 14.557 0 0 1 235.035 47.534 Q 241.309 42.969 252.686 42.969 L 261.475 42.969 L 261.475 38.818 A 12.803 12.803 0 0 0 261.078 35.541 A 9.196 9.196 0 0 0 258.643 31.274 Q 255.811 28.467 250.294 28.467 A 15.837 15.837 0 0 0 246.493 28.901 A 11.865 11.865 0 0 0 242.188 30.908 A 9.506 9.506 0 0 0 240.359 32.664 A 6.582 6.582 0 0 0 238.917 36.816 L 229.835 36.816 A 11.565 11.565 0 0 1 231.223 31.387 A 15.652 15.652 0 0 1 232.642 29.175 A 18.11 18.11 0 0 1 237.712 24.67 A 22.807 22.807 0 0 1 240.259 23.34 A 24.987 24.987 0 0 1 248.878 21.255 A 29.659 29.659 0 0 1 250.831 21.191 A 29.439 29.439 0 0 1 256.58 21.719 Q 261.72 22.743 265.137 25.757 A 15.51 15.51 0 0 1 270.174 34.935 A 22.2 22.2 0 0 1 270.508 38.33 L 270.508 62.646 A 46.423 46.423 0 0 0 270.708 67.1 Q 271.117 71.332 272.364 74.219 L 272.364 75 Z M 261.475 60.205 L 261.475 49.365 L 254.395 49.365 A 42.398 42.398 0 0 0 248.753 49.707 Q 242.578 50.541 239.882 53.412 A 7.953 7.953 0 0 0 237.794 59.082 A 10.222 10.222 0 0 0 238.175 61.952 A 7.628 7.628 0 0 0 240.626 65.723 A 9.928 9.928 0 0 0 245.22 67.87 A 13.989 13.989 0 0 0 247.901 68.115 Q 252.149 68.115 255.958 65.918 Q 259.766 63.721 261.475 60.205 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 294.483 0 L 294.483 75 L 285.45 75 L 285.45 0 L 294.483 0 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 306.544 48.73 L 306.544 48.096 A 33.961 33.961 0 0 1 307.509 39.856 A 28.587 28.587 0 0 1 309.595 34.131 A 23.999 23.999 0 0 1 314.203 27.625 A 21.745 21.745 0 0 1 318.091 24.561 A 22.626 22.626 0 0 1 328.585 21.258 A 27.674 27.674 0 0 1 330.518 21.191 A 25.301 25.301 0 0 1 338.889 22.521 A 21.518 21.518 0 0 1 347.974 28.662 A 25.439 25.439 0 0 1 353.741 40.092 A 37.474 37.474 0 0 1 354.639 48.535 L 354.639 49.17 A 34.597 34.597 0 0 1 353.756 57.133 A 28.637 28.637 0 0 1 351.685 63.013 A 22.849 22.849 0 0 1 345.826 70.68 A 22.23 22.23 0 0 1 343.238 72.559 A 22.726 22.726 0 0 1 333.018 75.876 A 28.098 28.098 0 0 1 330.616 75.977 A 25.13 25.13 0 0 1 322.278 74.647 A 21.534 21.534 0 0 1 313.209 68.506 Q 306.544 61.035 306.544 48.73 Z M 318.349 61.221 A 17.164 17.164 0 0 0 319.703 63.281 Q 323.78 68.604 330.616 68.604 Q 337.379 68.604 341.409 63.397 A 16.377 16.377 0 0 0 341.553 63.208 Q 344.772 58.923 345.434 51.912 A 40.609 40.609 0 0 0 345.606 48.096 Q 345.606 40.6 342.537 35.556 A 17.624 17.624 0 0 0 341.48 34.009 A 13.676 13.676 0 0 0 336.868 30.059 Q 334.188 28.681 330.848 28.616 A 17.075 17.075 0 0 0 330.518 28.613 A 13.542 13.542 0 0 0 324.666 29.852 Q 321.85 31.181 319.727 33.936 A 17.656 17.656 0 0 0 316.926 39.464 Q 316.154 41.913 315.84 44.876 A 40.845 40.845 0 0 0 315.626 49.17 Q 315.626 56.353 318.349 61.221 Z" />
      </g>
      <g transform="translate(-367, 100)">
        <path style={animate && styles.animateM} stroke={theme.palette.secondary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" fill="transparent" d="M 367.383 3.906 L 379.493 3.906 L 402.735 61.914 L 425.977 3.906 L 438.135 3.906 L 438.135 75 L 428.76 75 L 428.76 47.314 L 429.639 17.432 L 406.299 75 L 399.122 75 L 375.831 17.578 L 376.758 47.314 L 376.758 75 L 367.383 75 L 367.383 3.906 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 495.362 75 L 485.889 75 Q 485.108 73.438 484.62 69.434 A 21.481 21.481 0 0 1 477.711 74.341 A 20.155 20.155 0 0 1 469.581 75.977 A 22.593 22.593 0 0 1 463.7 75.248 A 16.988 16.988 0 0 1 456.763 71.558 A 14.202 14.202 0 0 1 451.779 61.217 A 17.812 17.812 0 0 1 451.758 60.352 A 17.307 17.307 0 0 1 452.697 54.521 A 14.557 14.557 0 0 1 458.033 47.534 Q 464.307 42.969 475.684 42.969 L 484.473 42.969 L 484.473 38.818 A 12.803 12.803 0 0 0 484.076 35.541 A 9.196 9.196 0 0 0 481.641 31.274 Q 478.809 28.467 473.292 28.467 A 15.837 15.837 0 0 0 469.491 28.901 A 11.865 11.865 0 0 0 465.186 30.908 A 9.506 9.506 0 0 0 463.357 32.664 A 6.582 6.582 0 0 0 461.915 36.816 L 452.833 36.816 A 11.565 11.565 0 0 1 454.221 31.387 A 15.652 15.652 0 0 1 455.64 29.175 A 18.11 18.11 0 0 1 460.71 24.67 A 22.807 22.807 0 0 1 463.257 23.34 A 24.987 24.987 0 0 1 471.876 21.255 A 29.659 29.659 0 0 1 473.829 21.191 A 29.439 29.439 0 0 1 479.578 21.719 Q 484.718 22.743 488.135 25.757 A 15.51 15.51 0 0 1 493.173 34.935 A 22.2 22.2 0 0 1 493.506 38.33 L 493.506 62.646 A 46.423 46.423 0 0 0 493.706 67.1 Q 494.115 71.332 495.362 74.219 L 495.362 75 Z M 484.473 60.205 L 484.473 49.365 L 477.393 49.365 A 42.398 42.398 0 0 0 471.751 49.707 Q 465.576 50.541 462.88 53.412 A 7.953 7.953 0 0 0 460.792 59.082 A 10.222 10.222 0 0 0 461.173 61.952 A 7.628 7.628 0 0 0 463.624 65.723 A 9.928 9.928 0 0 0 468.218 67.87 A 13.989 13.989 0 0 0 470.899 68.115 Q 475.147 68.115 478.956 65.918 Q 482.764 63.721 484.473 60.205 Z" />
        <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 513.819 22.168 L 525.391 41.455 L 537.11 22.168 L 547.657 22.168 L 530.372 48.291 L 548.194 75 L 537.745 75 L 525.538 55.225 L 513.331 75 L 502.833 75 L 520.655 48.291 L 503.37 22.168 L 513.819 22.168 Z" />
      </g>
    </svg> :
    <svg viewBox="-2 0 551 95" {...props}>
      <path style={animate && styles.animateC} stroke={theme.palette.secondary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" fill="transparent" d="M 45.313 52.441 L 54.737 52.441 A 33.516 33.516 0 0 1 52.948 60.176 A 23.323 23.323 0 0 1 46.412 69.849 A 23.964 23.964 0 0 1 35.928 75.097 Q 32.304 75.949 28.058 75.976 A 43.766 43.766 0 0 1 27.784 75.977 A 28.37 28.37 0 0 1 18.127 74.398 A 24.377 24.377 0 0 1 7.593 66.943 A 30.992 30.992 0 0 1 1.352 54.459 Q 0.169 49.822 0.022 44.353 A 58.464 58.464 0 0 1 0.001 42.773 L 0.001 35.938 A 47.233 47.233 0 0 1 0.778 27.171 A 35.875 35.875 0 0 1 3.541 18.506 A 28.212 28.212 0 0 1 8.601 11 A 25.109 25.109 0 0 1 13.575 6.958 A 26.83 26.83 0 0 1 24.974 3.122 A 33.631 33.631 0 0 1 28.614 2.93 A 32.911 32.911 0 0 1 36.459 3.814 A 22.95 22.95 0 0 1 46.778 9.253 A 24.003 24.003 0 0 1 53.385 19.943 A 34.289 34.289 0 0 1 54.737 26.758 L 45.313 26.758 Q 44.548 21.435 42.663 17.952 A 13.588 13.588 0 0 0 40.015 14.453 A 13.407 13.407 0 0 0 34.401 11.374 Q 32.323 10.787 29.842 10.672 A 26.621 26.621 0 0 0 28.614 10.645 A 19.477 19.477 0 0 0 22.187 11.658 A 16.253 16.253 0 0 0 14.527 17.285 Q 9.424 23.926 9.424 36.182 L 9.424 43.066 Q 9.424 51.672 12.098 57.659 A 22.422 22.422 0 0 0 14.258 61.475 A 15.335 15.335 0 0 0 25.352 68.168 A 20.16 20.16 0 0 0 27.784 68.311 A 27.742 27.742 0 0 0 32.311 67.965 Q 34.75 67.561 36.702 66.685 A 12.836 12.836 0 0 0 39.771 64.771 A 12.529 12.529 0 0 0 42.604 61.204 Q 44.421 57.904 45.26 52.774 A 43.507 43.507 0 0 0 45.313 52.441 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 63.819 50.049 L 63.819 48.389 A 35.217 35.217 0 0 1 64.666 40.511 A 28.681 28.681 0 0 1 66.822 34.351 A 24.345 24.345 0 0 1 71.794 27.4 A 22.592 22.592 0 0 1 75.22 24.683 A 21.115 21.115 0 0 1 86.915 21.191 A 25.048 25.048 0 0 1 93.727 22.068 A 18.214 18.214 0 0 1 102.93 27.979 Q 108.643 34.766 108.643 47.412 L 108.643 51.172 L 72.852 51.172 A 23.461 23.461 0 0 0 73.642 56.817 A 16.845 16.845 0 0 0 77.418 63.794 A 14.244 14.244 0 0 0 87.796 68.589 A 18.354 18.354 0 0 0 88.526 68.604 A 19.593 19.593 0 0 0 92.172 68.281 Q 94.495 67.841 96.367 66.801 A 12.287 12.287 0 0 0 96.631 66.65 Q 99.952 64.697 102.442 61.475 L 107.96 65.771 A 21.402 21.402 0 0 1 92.407 75.675 A 30.546 30.546 0 0 1 88.038 75.977 A 26.605 26.605 0 0 1 79.691 74.722 A 21.937 21.937 0 0 1 70.557 68.921 A 23.728 23.728 0 0 1 64.51 57.13 A 34.476 34.476 0 0 1 63.819 50.049 Z M 73.145 43.75 L 99.61 43.75 L 99.61 43.066 Q 99.321 37.968 97.424 34.569 A 12.557 12.557 0 0 0 95.899 32.397 A 10.923 10.923 0 0 0 88.627 28.706 A 15.439 15.439 0 0 0 86.915 28.613 Q 81.446 28.613 77.735 32.593 Q 74.473 36.09 73.399 42.059 A 30.452 30.452 0 0 0 73.145 43.75 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 163.721 48.34 L 163.721 49.17 A 42.978 42.978 0 0 1 163.009 57.233 Q 162.101 61.985 160.035 65.753 A 23.997 23.997 0 0 1 158.204 68.604 A 17.447 17.447 0 0 1 145.557 75.861 A 22.362 22.362 0 0 1 143.262 75.977 Q 133.643 75.977 128.126 69.873 L 128.126 95.313 L 119.092 95.313 L 119.092 22.168 L 127.344 22.168 L 127.784 28.027 Q 133.301 21.191 143.116 21.191 A 20.317 20.317 0 0 1 150.195 22.376 A 17.739 17.739 0 0 1 158.179 28.369 Q 162.709 34.236 163.536 43.855 A 52.378 52.378 0 0 1 163.721 48.34 Z M 128.126 36.133 L 128.126 61.377 A 14.793 14.793 0 0 0 131.769 65.781 Q 134.756 68.162 138.938 68.535 A 17.933 17.933 0 0 0 140.528 68.604 A 13.154 13.154 0 0 0 145.701 67.618 Q 148.699 66.35 150.855 63.452 Q 154.688 58.301 154.688 48.145 A 33.969 33.969 0 0 0 154.258 42.565 Q 153.409 37.47 150.879 34.033 A 12.07 12.07 0 0 0 142.144 28.949 A 15.709 15.709 0 0 0 140.43 28.857 A 14.51 14.51 0 0 0 134.699 29.945 Q 130.641 31.671 128.126 36.133 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 184.229 0 L 184.229 28.564 Q 190.235 21.191 199.854 21.191 A 21.166 21.166 0 0 1 206.893 22.264 Q 216.637 25.694 216.749 40.088 L 216.749 75 L 207.715 75 L 207.715 40.039 Q 207.667 34.326 205.103 31.592 Q 203.035 29.386 199.109 28.96 A 18.441 18.441 0 0 0 197.12 28.857 A 13.921 13.921 0 0 0 192.516 29.599 A 12.481 12.481 0 0 0 189.405 31.201 A 15.915 15.915 0 0 0 184.229 37.354 L 184.229 75 L 175.196 75 L 175.196 0 L 184.229 0 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 272.364 75 L 262.891 75 Q 262.11 73.438 261.622 69.434 A 21.481 21.481 0 0 1 254.712 74.341 A 20.155 20.155 0 0 1 246.583 75.977 A 22.593 22.593 0 0 1 240.702 75.248 A 16.988 16.988 0 0 1 233.765 71.558 A 14.202 14.202 0 0 1 228.781 61.217 A 17.812 17.812 0 0 1 228.76 60.352 A 17.307 17.307 0 0 1 229.699 54.521 A 14.557 14.557 0 0 1 235.035 47.534 Q 241.309 42.969 252.686 42.969 L 261.475 42.969 L 261.475 38.818 A 12.803 12.803 0 0 0 261.078 35.541 A 9.196 9.196 0 0 0 258.643 31.274 Q 255.811 28.467 250.294 28.467 A 15.837 15.837 0 0 0 246.493 28.901 A 11.865 11.865 0 0 0 242.188 30.908 A 9.506 9.506 0 0 0 240.359 32.664 A 6.582 6.582 0 0 0 238.917 36.816 L 229.835 36.816 A 11.565 11.565 0 0 1 231.223 31.387 A 15.652 15.652 0 0 1 232.642 29.175 A 18.11 18.11 0 0 1 237.712 24.67 A 22.807 22.807 0 0 1 240.259 23.34 A 24.987 24.987 0 0 1 248.878 21.255 A 29.659 29.659 0 0 1 250.831 21.191 A 29.439 29.439 0 0 1 256.58 21.719 Q 261.72 22.743 265.137 25.757 A 15.51 15.51 0 0 1 270.174 34.935 A 22.2 22.2 0 0 1 270.508 38.33 L 270.508 62.646 A 46.423 46.423 0 0 0 270.708 67.1 Q 271.117 71.332 272.364 74.219 L 272.364 75 Z M 261.475 60.205 L 261.475 49.365 L 254.395 49.365 A 42.398 42.398 0 0 0 248.753 49.707 Q 242.578 50.541 239.882 53.412 A 7.953 7.953 0 0 0 237.794 59.082 A 10.222 10.222 0 0 0 238.175 61.952 A 7.628 7.628 0 0 0 240.626 65.723 A 9.928 9.928 0 0 0 245.22 67.87 A 13.989 13.989 0 0 0 247.901 68.115 Q 252.149 68.115 255.958 65.918 Q 259.766 63.721 261.475 60.205 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 294.483 0 L 294.483 75 L 285.45 75 L 285.45 0 L 294.483 0 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 306.544 48.73 L 306.544 48.096 A 33.961 33.961 0 0 1 307.509 39.856 A 28.587 28.587 0 0 1 309.595 34.131 A 23.999 23.999 0 0 1 314.203 27.625 A 21.745 21.745 0 0 1 318.091 24.561 A 22.626 22.626 0 0 1 328.585 21.258 A 27.674 27.674 0 0 1 330.518 21.191 A 25.301 25.301 0 0 1 338.889 22.521 A 21.518 21.518 0 0 1 347.974 28.662 A 25.439 25.439 0 0 1 353.741 40.092 A 37.474 37.474 0 0 1 354.639 48.535 L 354.639 49.17 A 34.597 34.597 0 0 1 353.756 57.133 A 28.637 28.637 0 0 1 351.685 63.013 A 22.849 22.849 0 0 1 345.826 70.68 A 22.23 22.23 0 0 1 343.238 72.559 A 22.726 22.726 0 0 1 333.018 75.876 A 28.098 28.098 0 0 1 330.616 75.977 A 25.13 25.13 0 0 1 322.278 74.647 A 21.534 21.534 0 0 1 313.209 68.506 Q 306.544 61.035 306.544 48.73 Z M 318.349 61.221 A 17.164 17.164 0 0 0 319.703 63.281 Q 323.78 68.604 330.616 68.604 Q 337.379 68.604 341.409 63.397 A 16.377 16.377 0 0 0 341.553 63.208 Q 344.772 58.923 345.434 51.912 A 40.609 40.609 0 0 0 345.606 48.096 Q 345.606 40.6 342.537 35.556 A 17.624 17.624 0 0 0 341.48 34.009 A 13.676 13.676 0 0 0 336.868 30.059 Q 334.188 28.681 330.848 28.616 A 17.075 17.075 0 0 0 330.518 28.613 A 13.542 13.542 0 0 0 324.666 29.852 Q 321.85 31.181 319.727 33.936 A 17.656 17.656 0 0 0 316.926 39.464 Q 316.154 41.913 315.84 44.876 A 40.845 40.845 0 0 0 315.626 49.17 Q 315.626 56.353 318.349 61.221 Z" />
      <path style={animate && styles.animateM} stroke={theme.palette.secondary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" fill="transparent" d="M 367.383 3.906 L 379.493 3.906 L 402.735 61.914 L 425.977 3.906 L 438.135 3.906 L 438.135 75 L 428.76 75 L 428.76 47.314 L 429.639 17.432 L 406.299 75 L 399.122 75 L 375.831 17.578 L 376.758 47.314 L 376.758 75 L 367.383 75 L 367.383 3.906 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 495.362 75 L 485.889 75 Q 485.108 73.438 484.62 69.434 A 21.481 21.481 0 0 1 477.711 74.341 A 20.155 20.155 0 0 1 469.581 75.977 A 22.593 22.593 0 0 1 463.7 75.248 A 16.988 16.988 0 0 1 456.763 71.558 A 14.202 14.202 0 0 1 451.779 61.217 A 17.812 17.812 0 0 1 451.758 60.352 A 17.307 17.307 0 0 1 452.697 54.521 A 14.557 14.557 0 0 1 458.033 47.534 Q 464.307 42.969 475.684 42.969 L 484.473 42.969 L 484.473 38.818 A 12.803 12.803 0 0 0 484.076 35.541 A 9.196 9.196 0 0 0 481.641 31.274 Q 478.809 28.467 473.292 28.467 A 15.837 15.837 0 0 0 469.491 28.901 A 11.865 11.865 0 0 0 465.186 30.908 A 9.506 9.506 0 0 0 463.357 32.664 A 6.582 6.582 0 0 0 461.915 36.816 L 452.833 36.816 A 11.565 11.565 0 0 1 454.221 31.387 A 15.652 15.652 0 0 1 455.64 29.175 A 18.11 18.11 0 0 1 460.71 24.67 A 22.807 22.807 0 0 1 463.257 23.34 A 24.987 24.987 0 0 1 471.876 21.255 A 29.659 29.659 0 0 1 473.829 21.191 A 29.439 29.439 0 0 1 479.578 21.719 Q 484.718 22.743 488.135 25.757 A 15.51 15.51 0 0 1 493.173 34.935 A 22.2 22.2 0 0 1 493.506 38.33 L 493.506 62.646 A 46.423 46.423 0 0 0 493.706 67.1 Q 494.115 71.332 495.362 74.219 L 495.362 75 Z M 484.473 60.205 L 484.473 49.365 L 477.393 49.365 A 42.398 42.398 0 0 0 471.751 49.707 Q 465.576 50.541 462.88 53.412 A 7.953 7.953 0 0 0 460.792 59.082 A 10.222 10.222 0 0 0 461.173 61.952 A 7.628 7.628 0 0 0 463.624 65.723 A 9.928 9.928 0 0 0 468.218 67.87 A 13.989 13.989 0 0 0 470.899 68.115 Q 475.147 68.115 478.956 65.918 Q 482.764 63.721 484.473 60.205 Z" />
      <path fill={theme.palette.type !== 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText} strokeLinejoin="round" d="M 513.819 22.168 L 525.391 41.455 L 537.11 22.168 L 547.657 22.168 L 530.372 48.291 L 548.194 75 L 537.745 75 L 525.538 55.225 L 513.331 75 L 502.833 75 L 520.655 48.291 L 503.37 22.168 L 513.819 22.168 Z" />
    </svg>

  )
})