import React from 'react';
//import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next';

const languagesMap = {
  'fr': 'Français',
  'en': 'English',
  'es': 'Español'
}

const Footer = ({setShowCookieSettings}) => {

  const {language, languages, changeLanguage} = useI18next();

  return (
    <footer className=" bg-gray-100 shadow-primary">
      <div className="container mx-auto pt-16 pb-4 px-3 mt-24  text-gray-800">
        <div className="flex -mx-3 flex-col md:flex-row">
          <div className="flex-1 px-3 flex flex-col">
            <div className="w-48 mr-3">
              <LogoIcon />
            </div>
            <div className="mt-3 flex items-center">
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 stroke- stroke-current" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg> */}
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 stroke-1 stroke-current" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
              <select 
                className={`
                  bg-transparent
                  border-none
                  focus:shadow-none
                  focus:ring-transparent
                  focus:border-primary
                  focus:shadow-primary
                `} 
                value={language} 
                onChange={(e) => { changeLanguage(e.currentTarget.value) }}
              >
                {
                  languages.map((lng) => {
                      return <option key={lng} value={lng} >{languagesMap[lng] || lng}</option>
                    }
                  )
                }
              </select>
            </div>
          </div>
          <div className="flex-1 px-3 mt-8 md:mt-0">
            <h2 className="text-lg font-semibold"><Trans>Liens</Trans></h2>
            <ul className="mt-4 leading-loose">
              <li>
                <Link to="/legal-notice"><Trans>Mentions légales</Trans></Link>
              </li>
              <li>
                <Link to="/term-and-conditions-of-use"><Trans>Conditions générales d'utilisations</Trans></Link>
              </li>
              <li>
                <Link to="/privacy-policy"><Trans>Politique de confidentialité</Trans></Link>
              </li>
              <li>
                <a
                  href="#" 
                  onClick={(e)=> {
                    e.preventDefault();
                    setShowCookieSettings(Date.now())
                  }}
                ><Trans>Réglages des cookies</Trans></a>
              </li>
            </ul>
          </div>
          <div className="flex-1 px-3"></div>
          {/* <div className="flex-1 px-3 mt-8 sm:mt-0">
            <h2 className="text-lg font-semibold">Social Media</h2>
            <ul className="mt-4 leading-loose">
              <li>
                <a href="https://dev.to/changoman">Dev.to</a>
              </li>
              <li>
                <a href="https://twitter.com/HuntaroSan">Twitter</a>
              </li>
              <li>
                <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="mt-8 md:mt-0">
          © 2021 CephaloMax
        </div>
      </div>
    </footer>
  );
}

export default Footer;
