import React, {useEffect, useState} from "react";
//import {initializeAndTrack} from "gatsby-plugin-gdpr-cookies";

const isBrowser = () => typeof window !== "undefined"
const sendEvent = (event) => {
  if (isBrowser()){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}

const CookieNotice = ({
                    children,
                    backgroundClasses,
                    backgroundWrapperClasses,
                    acceptButtonText,
                    acceptButtonClasses,
                    buttonWrapperClasses,
                    declineButton,
                    declineButtonClasses,
                    declineButtonText,
                    wrapperClasses,
                    personalizeButtonClasses,
                    personalizeButtonText,
                    cookies,
                    cookieDays,
                    personalizeButtonEnable,
                    cookieListClasses,
                    cookieMapClasses,
                    cookieTitleClasses,
                    personalizeValidationClasses,
                    personalizeValidationWrapperClasses,
                    personalizeValidationText,
                    checkboxClasses,
                    showCookieSettings,
                }) => {

    const [displayCookieList, setDisplayCookieList] = useState();
    const [displayCookieNotice, setDisplayCookieNotice] = useState();
    const domain = isBrowser() && window.location.host.split(':')[0].split('.').splice(-2).join('.')

    const styles = {
        background: backgroundClasses,
        backgroundWrapper: backgroundWrapperClasses,
        acceptBtn: acceptButtonClasses,
        buttonWrapper: buttonWrapperClasses,
        declineBtn: declineButtonClasses,
        wrapper: wrapperClasses,
        personalizeButton: personalizeButtonClasses,
        cookiesList: cookieListClasses,
        cookieMap: cookieMapClasses,
        cookieTitle: cookieTitleClasses,
        personalizeValidation: personalizeValidationClasses,
        personalizeValidationWrapper: personalizeValidationWrapperClasses,
        checkbox: checkboxClasses
    }

    const text = {
        acceptButton: acceptButtonText,
        declineButton: declineButtonText,
        personalizeButton: personalizeButtonText,
        personalizeValidation: personalizeValidationText
    }

    const prop = {
        declineButtonActivated: declineButton,
        cookiesList: cookies,
        cookieDays: cookieDays,
        personalizeButton: personalizeButtonEnable
    }

    let date = new Date();
    date.setTime(date.getTime() + (prop.cookieDays * 24 * 60 * 60 * 1000));
    const expires = "; expires=" + date.toUTCString();

    const acceptCookie = () => {
        prop.cookiesList.forEach(c => {
            document.cookie = c.name + "=granted" + expires + "; path=/;domain="+domain;
        })
        updateAndClose()
    }

    const declineCookie = () => {
        prop.cookiesList.forEach(c => {
            if (c.editable)
              document.cookie = c.name + "=denied" + expires + "; path=/;domain="+domain;
            else
              document.cookie = c.name + "=" + c.default + expires + "; path=/;domain="+domain;
             
            if (c.cookies && c.cookies.length) {
              deleteCookies(c.cookies)
            }   
        })

        updateAndClose()
    }

    const validatePreferences = (e) => {
        e.preventDefault();
        prop.cookiesList.forEach(c => {
                if (document.getElementById(c.name).checked) {
                  document.cookie = c.name + "=granted" + expires + "; path=/;domain="+domain;
                }
                else {
                  document.cookie = c.name + "=denied" + expires + "; path=/;domain="+domain;
                  if (c.cookies && c.cookies.length) {
                    deleteCookies(c.cookies)
                  } 
                }

                
            }
        )
        updateAndClose()
    }

    const updateAndClose = () => {
      sendEvent({ event: 'consent_update'})
      setDisplayCookieNotice(false)
      setDisplayCookieList(false)
      // if(showCookieSettings)
      //   window.location.reload()
    }

    const cookieExist = (name) => {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin === -1) {
            begin = dc.indexOf(prefix);
            if (begin !== 0) return null;
        } else {
            begin += 2;
        }
        
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }

        return decodeURI(dc.substring(begin + prefix.length, end));
    }

    const deleteCookies = (regExpList = []) => {
      const cookiesArray = document.cookie.split(";");
      //loop through the array and check if the cookie name is what we want
      cookiesArray.forEach(cookie => {
        var cookieName = cookie.split("=")[0].trim();

        regExpList.forEach(regex => {
          if(cookieName.match(regex))
            document.cookie = cookieName + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
        })
      })
    }

    useEffect(() => {
        prop.cookiesList.map(c => {
            if (!cookieExist(c.name)) {
                return setDisplayCookieNotice(true)
            }
        })
    })

    
    useEffect(() => {
      if(!showCookieSettings)
        return
      setDisplayCookieNotice(true)
    }, [showCookieSettings])

    return (
        <>
            {displayCookieNotice && (
                <div className={styles.backgroundWrapper}>
                    <div className={styles.background}>
                        {children}
                        {displayCookieList && (
                            <form onSubmit={validatePreferences}>
                                <div className={styles.cookiesList}>
                                    {prop.cookiesList.map(c => {
                                        return (
                                            <div key={c.name} className={styles.cookieMap}>
                                                <input type="checkbox"
                                                       defaultChecked={c.editable ? cookieExist(c.name) ? cookieExist(c.name) === 'granted' ? true : false : c.default : c.default} disabled={!c.editable}
                                                       value={c.name} id={c.name} className={styles.checkbox}/>
                                                <label htmlFor={c.name} className={styles.cookieTitle}>{c.title}</label>
                                                <p>{c.text}</p>
                                            </div>
                                        )
                                    })}
                                    <div className={styles.personalizeValidationWrapper}>
                                        <button type={"submit"} className={styles.personalizeValidation}>
                                            {text.personalizeValidation}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )
                        }
                        {!displayCookieList && (
                            <div className={styles.wrapper}>
                                {prop.personalizeButton && (
                                    <button onClick={() => setDisplayCookieList(true)}
                                            className={styles.personalizeButton}>{text.personalizeButton}</button>
                                )}
                                <div className={styles.buttonWrapper}>
                                    {prop.declineButtonActivated && (
                                        <button onClick={declineCookie}
                                                className={styles.declineBtn}>{text.declineButton}</button>
                                    )}
                                    <button onClick={acceptCookie}
                                            className={styles.acceptBtn}>{text.acceptButton}</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

CookieNotice.defaultProps = {
    backgroundClasses: "container",
    backgroundWrapperClasses: "bg-cookie fixed-bottom py-5",
    acceptButtonText: "Accept",
    buttonWrapperClasses: "float-end",
    acceptButtonClasses: "btn btn-primary btn-lg mx-2",
    declineButton: true,
    declineButtonText: "Decline",
    declineButtonClasses: "btn btn-secondary btn-lg mx-2",
    wrapperClasses: "d-flex justify-content-between cookie-notice",
    personalizeButtonClasses: "btn btn-secondary",
    personalizeButtonText: "Personalize",
    cookies:
        [
            {
                name: 'necessary',
                editable: false,
                default: true,
                title: 'Essentiel',
                text: 'Essential cookie are necessary for the proper function of the website'
            }
        ],
    cookieDays: 365,
    personalizeButtonEnable: true,
    cookieListClasses: "row cookie-list",
    cookieMapClasses: "col-12 col-md-6",
    cookieTitleClasses: "",
    personalizeValidationClasses: "btn btn-primary btn-lg float-end",
    personalizeValidationWrapperClasses: "col-12",
    personalizeValidationText: "Save",
    checkboxClasses: "",
    location: undefined,
}

export default CookieNotice;