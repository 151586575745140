import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import LinkButton from '../LinkButton';
// import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { useMeasure } from "react-use";
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next';

const Header = ({location = { pathname: '/'}}) => {
  const [ref, { height }] = useMeasure();
  console.log('useMeasure', height)
  
  if(location.pathname === "/") {
    return (
      <header className="sticky top-0 bg-white shadow z-10 overflow-hidden" ref={ref}>
        <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
          <div className="flex items-center text-2xl">
            <AnchorLink offset={height} className="px-4" href="#home">
              <div className="w-48 lg:w-64 mr-3">
                <LogoIcon />
              </div>
            </AnchorLink>
          </div>
          <div className="flex mt-4 sm:mt-0">
            <AnchorLink offset={height} className="md:px-4 px-2" href="#features">
              <Trans>features</Trans>
            </AnchorLink>
            <AnchorLink offset={height} className="md:px-4 px-2" href="#price">
              <Trans>prices</Trans>
            </AnchorLink>
            <AnchorLink offset={height} className="md:px-4 px-2" href="#contact">
              <Trans>contact</Trans>
            </AnchorLink>
            <Link className="md:px-4 px-2" to="/faq">
              <Trans>faq</Trans>
            </Link>
            <a 
              className="md:hidden px-2"
              href="https://app.cephalomax.com"
              target="_blank"
            >
              <Trans>header.connection</Trans>
            </a>
          </div>
          <div className="hidden md:block ml-8">
            <LinkButton 
              className="text-sm"
              href="https://app.cephalomax.com"
              target="_blank"
            >
              <Trans>header.connection</Trans>
            </LinkButton>
          </div>
        </div>
      </header>
    )
  } else {
    return (
      <header className="sticky top-0 bg-white drop-shadow z-10 overflow-hidden">
        <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
          <div className="flex items-center text-2xl">
            <Link to="/" className="px-4">
              <div className="w-48 lg:w-64 mr-3">
                <LogoIcon />
              </div>
            </Link>
          </div>
          <div className="flex mt-4 sm:mt-0">
            <Link className="px-4" to="/#features">
              <Trans>features</Trans>
            </Link>
            <Link className="px-4" to="/#price">
              <Trans>prices</Trans>
            </Link>
            <Link className="px-4" to="/#contact">
              <Trans>contact</Trans>
            </Link>
            <Link className="px-4" to="/faq">
              <Trans>faq</Trans>
            </Link>
          </div>
          <div className="px-4 hidden md:block ml-8">
            <LinkButton 
              className="text-sm"
              href="https://app.cephalomax.com"
              target="_blank"
            >
              <Trans>header.connection</Trans>
            </LinkButton>
          </div>
        </div>
      </header>
    )
  }
};

export default Header;
