import React from 'react';
// import { Link } from "gatsby";
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next';
import CookieNotice from "../CookieNotice";

//import "./cookie-consent.css"

const CookieConsent = ({
  location,
  showCookieSettings,
}) => {

  const {t} = useI18next()
  return (
    <CookieNotice
      location={location}
      showCookieSettings={showCookieSettings} 
      backgroundWrapperClasses="fixed bottom-0 left-0 right-0 cookie-consent-container"
      backgroundClasses={`
        bg-gray-200 
        p-5
        border-gray-400
      `}
      wrapperClasses="flex flex-col sm:flex-row justify-between"
      buttonWrapperClasses={`
        flex 
        flex-col
        sm:block
      `}
      acceptButtonClasses={`
        mt-4
        sm:mt-0
        sm:ml-8
        py-3 
        px-8
        text-sm
        bg-primary
        hover:bg-primary-darker
        rounded
        text-white
        hover:text-white
      `}
      declineButtonClasses={`
        mt-4
        sm:mt-0
        sm:ml-8
        py-3 
        px-8
        text-sm
        text-gray-700
        border-gray-700
        border-2
        hoverborder-black
        rounded
        hover:text-black
      `}
      personalizeButtonClasses={`
        py-3 
        px-8
        text-sm
        text-gray-700
        border-gray-700
        border-2
        hoverborder-black
        rounded
        hover:text-black
      `}
      personalizeValidationClasses={`
        py-3 
        px-8
        text-sm
        text-gray-700
        border-gray-700
        border-2
        hoverborder-black
        rounded
        hover:text-black
        flex-1 
        sm:flex-none
      `}
      cookies={[
        {
          name: 'necessary', 
          editable: false,
          default: true,
          title: t('Essentiel'),
          text: t('Les cookies essentiels sont nécessaires au fonctionnement du site'),
        },
        {
          name: 'gdpr-analytics',
          editable: true,
          default: true,
          title: t('Analyses'),
          text: t('Nous utilisons des cookies d\'analyse pour mesurer l\'audience du site'),
          cookies: [/^_ga/]
        },
        {
          name: 'gdpr-marketing',
          editable: true,
          default: true,
          title: t('Marketing'),
          text: t('Nous utilisons des cookies marketing pour vous montrer un contenu pertinent sur les produits, services ou sujets susceptibles de vous intéresser'),
          cookies: []
        }
      ]}
      cookieTitleClasses="mx-2 align-middle"
      acceptButtonText={t("Accepter les cookies")}
      declineButtonText={t("Tout refuser")}
      personalizeButtonText={t("Personnaliser")}
      personalizeValidationText={t("Enregistrer")}
      cookieMapClasses="my-5"
      personalizeValidationWrapperClasses="flex justify-start"
      checkboxClasses={`
        rounded-sm
        accent-primary
        //checked:bg-primary
        //focus:bg-primary
      `}
    >
      <Trans>
        <div className="text-xl" >La sécurité de vos données est notre priorité</div>
        <p className="my-5">
          CephaloMax dépose des cookies et utilise des informations non sensibles pour assurer le bon fonctionnement du site, mesurer l'audience et pour vous montrer un contenu pertinent sur les produits, services ou sujets susceptibles de vous intéresser. Pour en savoir plus sur les cookies, les données utilisées et leur traitement, vous pouvez consulter notre <Link to="/privacy-policy" >politique de confidentialité</Link>. 
        </p>
      </Trans>
    </CookieNotice>
  )
}

export default CookieConsent

