import React, {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import Seo from './Seo';
import CookieConset from './CookieConsent';
import {initializeAndTrack} from "gatsby-plugin-gdpr-cookies";

const Layout = ({ location, children }) => {
  const [showCookieSettings, setShowCookieSettings] = useState(false)
  //initializeAndTrack(location)

  return (
    <>
      <Header location={location}/>
      <Seo title="Cephalomax" location={location}/>
      <main className="text-gray-900">{children}</main>
      <Footer setShowCookieSettings={setShowCookieSettings}/>
      <CookieConset location={location} showCookieSettings={showCookieSettings} />
    </>
  );
};

export default Layout;
