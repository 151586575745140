import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { loadLanguages } from "i18next";

const Seo = ({description, keywords, title, image, url, author, location}) => {
    const { language, languages, originalPath, defaultLanguage} = useI18next()
    console.log('location seo', location)
    console.log('language seo', language)
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                console.log('StaticQuery', data)
                const metaDescription = description || data.site.siteMetadata[language].description;
                const metaTitle = title || data.site.siteMetadata[language].title;
                const metaAuthor = author || data.site.siteMetadata.author;
                const metaUrl = 
                  language === defaultLanguage ? 
                    `${process.env.GATSBY_PROXY}${originalPath}` :
                      `${process.env.GATSBY_PROXY}/${language}${originalPath}`;
                  
                //url || (location? process.env.GATSBY_PROXY+location.pathname : process.env.GATSBY_PROXY) || data.site.siteMetadata.url;
                const metaImage = image || process.env.GATSBY_PROXY + data.file.childImageSharp.fixed.src;
                const metaKeywords = keywords || ["Cephalomax site web", "Cephalomax website", "Cephalomax", "Application dentiste", "Dentiste"];
                return (
                    <Helmet
                        title={title}
                        htmlAttributes={{
                          lang: language
                        }}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription
                            },
                            {
                                property: `og:title`,
                                content: metaTitle
                            },
                            {
                                property: `og:description`,
                                content: metaDescription
                            },
                            {
                                property: `og:type`,
                                content: `website`
                            },
                            {
                                property: `og:image`,
                                content: metaImage
                            },
                            {
                                property: `og:local`,
                                content: language
                            },
                            {
                                property: `og:url`,
                                content: metaUrl
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`
                            },
                            {
                                name: `twitter:creator`,
                                content: metaAuthor
                            },
                            {
                                name: `twitter:title`,
                                content: metaTitle
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription
                            },
                            {
                                name: `robots`,
                                content: process.env.GATSBY_ROBOTS || `index`
                            },
                          ].concat(
                              metaKeywords && metaKeywords.lenght > 0 ? {
                                  name: `keywords`,
                                  content: metaKeywords.join(`, `),
                              }
                              : []
                        )}
                        link={[
                            {
                                rel: `canonical`,
                                href: metaUrl
                            },
                            ...languages.map(lang => {
                              let link
                              if(lang === defaultLanguage)
                                link = {
                                  rel: "alternate",
                                  href:`${process.env.GATSBY_PROXY}${originalPath}`,
                                  hrefLang: lang
                                }
                              else
                                link = {
                                  rel: "alternate",
                                  href:`${process.env.GATSBY_PROXY}/${lang}${originalPath}`,
                                  hrefLang: lang
                                }
            
                              return link
                            })
                        ]}
                    />
                );
            }}
        />
    );
};

const detailsQuery = graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          en {
            title
            description
          }
          es {
            title
            description
          }
          fr {
            title
            description
          }
          author
          image
        }
      }
      file(relativePath: {eq: "icon.png"}) {
        childImageSharp {
          fixed(fit: CONTAIN, height: 315, width: 600, background: "#fff") {
            src
          }
        }
      }
        
    }
`;

export default Seo;